/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/pink-bluegrey'
pre 
  font-weight: 400

  .number-line 
    color: #adadaf
  
  .string 
    color: #95c602
  
  .number 
    color: #f2b619
  
  .boolean 
    color: #0097f1
  
  .null 
    color: #727990
  
  .key 
    color: #000
  
